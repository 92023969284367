import autosize from 'autosize';
import jquery from './jqueryLib.js';
import 'popper.js';
import 'bootstrap';

$(function () {
    /*
    NAVIGATION LOGIC
    */

    $('.mobile-nav-trigger').on('keypress click', function (e) {
        if (e.which === 13 || e.type === 'click') {
            $('.primary-nav').toggleClass('open');
        }
    });

    $('.nav-close').on('keypress click', function (e) {
        if (e.which === 13 || e.type === 'click') {
            $(this).blur()
            $('.primary-nav').removeClass('open');
        }
    });

    window.addEventListener("resize", function () {
        $('.primary-nav').removeClass('open');

        // handleMobileNavMenuItems();

    });
    
    function handleMobileNavMenuItems() {
        let parentNavItemLinks = document.querySelectorAll('.menu-parent-item.menu-item-has-children>a');
        let subMenus = document.querySelectorAll('.menu-parent-item .sub-menu');
        
        if (parentNavItemLinks.length && subMenus.length) {
            subMenus.forEach((subMenu, i) => {
                let parentNavLink = `<li class="menu-child-item menu-item d-lg-none d-xl-none">${parentNavItemLinks[i].outerHTML}</li>`;
                subMenu.innerHTML = parentNavLink + subMenu.innerHTML;
            });
        }
    };
    
    handleMobileNavMenuItems();
    /*
    HELPTEXT/MODAL LOGIC
    */

    //help text trigger
    $('.help-text-icon').on('click', function () {
        var npc        = $('.nav-page-contents');
        var helpHeader = $(this).attr('data-help-header');
        var helpText   = $(this).attr('data-help-text');

        // display help text in sticky nav at large breakpoint and above
        if ($(npc).length && $(npc).is(':visible')) {
            var existingHeader = $(npc).children().first().text();
            var lastParagraph  = $(npc).children().last().text();

            // restore default text
            if (helpHeader === existingHeader) {
                lastParagraph = '<p>' + lastParagraph + '</p>';
                $(npc).hide();
                $(npc).html(lastParagraph).fadeIn(500);
            } else {
                // display new content
                helpHeader    = '<h5>' + helpHeader + '</h5>';
                helpText      = '<p class="m-b-0">' + helpText + '</p>';
                lastParagraph = '<p style="display:none;">' + lastParagraph + '</p>';
                $(npc).hide();
                $(npc).html(helpHeader + helpText + lastParagraph).fadeIn(500);
            }
        } else {
            helpHeader = '<h3>' + helpHeader + '</h3>';
            $('.help-text-content').html(helpHeader + helpText);
            $('.help-text-wrapper').fadeIn(500);
            $('.header').addClass('bring-to-front');
            $('.header-overlay').fadeIn(500);
        }
    });

    function closeHelp() {
        $('.help-text-wrapper').fadeOut(500);
        $('.header-overlay').fadeOut(500);
        $('.header').removeClass('bring-to-front');
    }

    // close modal when "x" is clicked
    $('.help-text-exit').on('click', function () {
        closeHelp();
    });

    // also close the modal when overlay is clicked
    $('.header-overlay').on('click', function () {
        closeHelp();
    });


    /*
    SEARCH LOGIC
    */

    // TODO: determine if this is needed, may be able to remove 
    $('.search-btn-trigger').on('click', function () {
        $('.search-container').slideDown(500);
        $('.search-container input').focus();
        $('body').addClass('overflow-hidden');
    });

    $('.exit-search').on('click', function () {
        $('.search-container').slideUp(500);
        $('body').removeClass('overflow-hidden');
    });


    /*
    ACCORDIONS LOGIC
    */

    $('.accordion').on('click', function () {
        var self = this;
        var rate = 200;
        var height = $(this).children('.accordion-content').height();
        var ms = height / rate * 500;
        if ($(this).hasClass('accordion-expanded')) {
            $(this).children('.accordion-content').slideUp(ms, function () {
                $(self).removeClass('accordion-expanded');
            });
        } else {
            resetAccordions(self);
            $(this).children('.accordion-content').slideDown(ms, function () {
                $(self).addClass('accordion-expanded');                
            });
        }
    });

    function resetAccordions(el) {
        $('.accordion').not(el).each(function () {
            var self = this;
            var rate = 200;
            var height = $(this).children('.accordion-content').height();
            var ms = height / rate * 500;
        
            $(this).children('.accordion-content').slideUp(ms, function () {
                $(this).children('.accordion-content').hide();
            });
            
            $(this).removeClass('accordion-expanded');
        });
    }


    /*
    MISC.
    */

    autosize($('textarea'));
});